import { CONSTANTS } from './constants'

export const RADAR_OPTIONS = {
  responsive: true,
  maintainAspectRatio: true,
  scale: {
    min: 0,
    max: 100,
    ticks: {
      stepSize: 25,
    },
    font: {
      family: 'Barlow, Helvetica, sans-serif',
      size: 10,
    },
  },
  scales: {
    r: {
      pointLabels: {
        font: {
          family: 'Barlow, Helvetica, sans-serif',
          size: 12,
          // style: 'italic'
        }
      }
    }
  },
  plugins: {
    title: {
      display: true,
      text: 'Title',
      font: {
        family: 'Barlow, Helvetica, sans-serif',
        size: 18,
      },
      color: '#000', // Optional: change the color of the title
      padding: {
        top: 10,
        bottom: 10,
      },
    },
    legend: {
      labels: {
        // This font property applies to legend labels
        font: {
          family: 'Barlow, Helvetica, sans-serif',
          size: 18,
        },
      },
    },
  },
}

export const barLegendMargin = {
  beforeInit(chart) {
    // console.log('chart.legend.fit:', chart.legend.fit)
    const fitValue = chart.legend.fit
    chart.legend.fit = function fit() {
      fitValue.bind(chart.legend)()
      return this.height += 30 // increases margin between legend and chart
    }
  },
}
export const BAR_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  scale: {
    min: 0,
    max: 100,
    ticks: {
      stepSize: 25,
    },
    font: {
      family: 'Barlow, Helvetica, sans-serif',
      size: 16,
    },
  },
  plugins: {
    title: {
      display: true,
      text: 'Title',
      font: {
        family: 'Barlow, Helvetica, sans-serif',
        size: 18,
      },
      color: '#000', // Optional: change the color of the title
      padding: {
        top: 10,
        bottom: 10,
      },
    },
    legend: {
      labels: {
        // This font property applies to legend labels
        font: {
          family: 'Barlow, Helvetica, sans-serif',
          size: 16,
        },
        // padding: 20, // increases margin above each line of the legend
      },
    },
  },
}

export const BORDER_THICKNESS = 2
const rgbaOpacity = ', 0.1)'
/**
 * called by ResultsModule1.js
 * @param jtLabels, object, 'label' portion of the jsonText object from Redux
 * @param chartLabels, [string], labels for each axis of chart, starting from top and going clockwise
 * @param benchAllData, [number], percentile data for the benchmark of all NPOs (always either 50 or 100)
 * @param benchCatResultsData, [number], percentile data for the benchmark of NPOs of same category compared with that of all NPOs
 * @param allResultsData, [number], percentile data for the NPO compared with the benchmark of all NPOs
 * @param catResultsData, [number], percentile data for the NPO compared with the benchmark of NPOs of same category
 * @param yearlyComp, object, { lastSurveyYear, selectedYear, benchType... } or undefined
 * @return object, the complete chart data for generating the chart
 *
 * Note: for yearly comparison, we use the following variables slightly differently:
 * benchAllData = benchmark data, either for all NPOs ('all') or only the NPOs of the same category ('cat'); (always either 50 or 100)
 * benchCatResultsData = last survey results percentiles compared with above
 * allResultsData = results of selected year's survey percentiles, also compared with benchAllData (or null)
 * catResultsData = always null
 */
export const getDataForChart = (jtLabels, chartLabels, benchAllData, benchCatResultsData, allResultsData, catResultsData, yearlyComp) => {
  // clean out any unuseful data to keep chart lean and easily readable
  benchCatResultsData = onlyPreserveValidData(benchCatResultsData) // if no benchmark for category, do not include
  catResultsData = onlyPreserveValidData(catResultsData) // if above is null, this is irrelevant
  allResultsData = onlyPreserveValidData(allResultsData) // if yearly comparison, this represents the results of the selected year which may be undefined

  const datasets = []
  if (benchAllData) datasets.push(
    {
      label: yearlyComp?.benchType === 'cat' ?
        jtLabels.benchmarkNPOsOfSameField || 'Benchmark of NPOs in Same Field'
        : jtLabels.benchmarkAllNPOs || 'Benchmark of all NPOs',  // this is the normal case
      data: benchAllData,
      backgroundColor: CONSTANTS.colors.benchLogoYellow.replace(')', rgbaOpacity), // add transparency
      borderColor: CONSTANTS.colors.benchLogoYellow,
      borderWidth: BORDER_THICKNESS,
    },
  )
  if (benchCatResultsData) datasets.push(
    {
      label: yearlyComp?.lastSurveyYear ?
        `${jtLabels.yourNpoPercentile || 'Your NPO\'s Percentile'} ${yearlyComp?.lastSurveyYear}`
        : jtLabels.benchmarkNPOsOfSameField || 'Benchmark of NPOs in Same Field',  // this is the normal case
      data: benchCatResultsData,
      backgroundColor: CONSTANTS.colors.benchLogoOrange.replace(')', rgbaOpacity),
      borderColor: CONSTANTS.colors.benchLogoOrange,
      borderWidth: BORDER_THICKNESS,
    },
  )
  if (allResultsData) datasets.push(
    {
      label: yearlyComp?.selectedYear ?
        `${jtLabels.yourNpoPercentile || 'Your NPO\'s Percentile'} ${yearlyComp?.selectedYear}`
        : jtLabels.yourPercentileAllNPOs || 'Your Percentile - All NPOs',
      data: allResultsData,
      backgroundColor: CONSTANTS.colors.benchLogoRed.replace(')', rgbaOpacity),
      borderColor: CONSTANTS.colors.benchLogoRed,
      borderWidth: BORDER_THICKNESS,
    },
  )
  if (catResultsData) datasets.push(
    {
      label: jtLabels.yourPercentileNPOsSameField || 'Your Percentile - NPOs of Same Field',
      data: catResultsData,
      backgroundColor: CONSTANTS.colors.benchLogoAnthracite.replace(')', rgbaOpacity),
      borderColor: CONSTANTS.colors.benchLogoAnthracite,
      borderWidth: BORDER_THICKNESS,
    },
  )
  // console.log('datasets:', datasets)
  return {
    labels: chartLabels,
    datasets,
  }
}

/**
 * checks if a chart dataset contains valid data to remove irrelevant data from chart
 * @param data, array of percentiles|null values
 * @return {data|null} returns same array or null
 */
const onlyPreserveValidData = (data) => {
  if (!data) return null
  let validDataPresent = false
  for (const percentile of data) {
    if (typeof percentile === 'number') validDataPresent = true
  }
  return validDataPresent ? data : null
}

export const BENCH_ALL_BG = CONSTANTS.colors.benchLogoOrange.replace(')', ', 0.2)') // add transparency
export const BENCH_ALL_BORDER = CONSTANTS.colors.benchLogoOrange
export const BENCH_CAT_BG = CONSTANTS.colors.benchLogoRed.replace(')', ', 0.2)')
export const BENCH_CAT_BORDER = CONSTANTS.colors.benchLogoRed
export const NPO_VS_ALL_BG = CONSTANTS.colors.benchLogoRed.replace(')', ', 0.2)')
export const NPO_VS_ALL_BORDER = CONSTANTS.colors.benchLogoRed
export const NPO_VS_CAT_BG = CONSTANTS.colors.benchLogoBlue.replace(')', ', 0.2)')
export const NPO_VS_CAT_BORDER = CONSTANTS.colors.benchLogoBlue

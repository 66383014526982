import { useEffect, useRef, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import BookConsultationCTA from '../common/BookConsultationCTA/BookConsultationCTA'
import ChartAndNav from './ChartAndNav/ChartAndNav'
import Footer from '../Footer/Footer'
import ResultsHeader from './ResultsHeader/ResultsHeader'
import ResultsTable from './ResultsTable/ResultsTable'
import { getChartDataMod1 } from './ResultsUtils/getChartDataMod1'
import { getChartDataMod2 } from './ResultsUtils/getChartDataMod2'
import { getChartDataMod3 } from './ResultsUtils/getChartDataMod3'
import { getChartDataMod4 } from './ResultsUtils/getChartDataMod4'
import { getChartDataMod5 } from './ResultsUtils/getChartDataMod5'
import {
  getBenchForAll, getBenchForCat,
  getDataForYearlyComparison, getPdfComparisonUrl,
} from './ResultsUtils/apiCallsForResults'
import { CONSTRUCT_ARRAY, CONSTRUCTS } from '../../utils/constructs'

// render the Module 1 (Step 2) survey results; called by Results.js
const YearlyComparison = () => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)
  // console.log('user:', user)
  const [module, setModule] = useState('1') // string, such as '1'
  const [lastSurveyYear, setLastSurveyYear] = useState('') // string, such as '2024'
  const [selectedYear, setSelectedYear] = useState('') // string, such as '2024'
  const [availableYears, setAvailableYears] = useState([]) // array of strings, such as ['2023','2024']
  const [benchType, setBenchType] = useState('all') // string, either 'all' or 'cat'
  const [benchModAll, setBenchModAll] = useState(null) // benchmark of all NPOs
  const [benchModCat, setBenchModCat] = useState(null) // benchmark of user's NPO category
  const [lastSurveyAllResults, setLastSurveyAllResults] = useState(null)  // results compared with all NPOs
  const [lastSurveyCatResults, setLastSurveyCatResults] = useState(null)  // results compared with user's NPO category
  const [selectSurveyAllResults, setSelectSurveyAllResults] = useState(null)  // results compared with all NPOs
  const [selectSurveyCatResults, setSelectSurveyCatResults] = useState(null)  // results compared with user's NPO category
  const [chartData, setChartData] = useState(null)
  const [chartImages, setChartImages] = useState({})
  const [pdfReportUrl, setPdfReportUrl] = useState('')
  const noCatBenchInfoHasBeenShown = useRef(null)

  useEffect(() => {
    // get latest benchmark
    if (user.strapiJwt && jt.toast) {
      // console.log('user:', user)
      if (!benchModAll) { // only run this once
        getBenchForAll(module, setBenchModAll).then()
      }
      if (!benchModCat) { // only run this once
        getBenchForCat(module, user.orgCategory, jt.toast, noCatBenchInfoHasBeenShown, setBenchModCat).then()
      }
      // get the latest answers for this user if they have fully completed this module
      if (benchModAll && benchModCat) {
        const constructs = CONSTRUCT_ARRAY[`M${module}`]
        getDataForYearlyComparison(
          module,
          user.orgID,
          jt.label,
          benchModAll,
          benchModCat,
          selectedYear,
          setLastSurveyYear,
          setAvailableYears,
          setLastSurveyAllResults,
          setLastSurveyCatResults,
          setSelectSurveyAllResults,
          setSelectSurveyCatResults,
          constructs,
          benchType,
          setPdfReportUrl,
        ).then()
      }
    }
    // eslint-disable-next-line
  }, [jt, user, benchModAll, benchModCat, selectedYear])

  useEffect(() => {
    setBenchModAll(null)
    setBenchModCat(null)
    // console.log('Second useEffect called!')
  }, [module])

  useEffect(() => {
    if (lastSurveyAllResults) {
      setChartData(getChartData())
      getPdfComparisonUrl(module, user.orgID, lastSurveyYear, selectedYear, benchType, setPdfReportUrl)
    }
    // console.log('Third useEffect called!')
    // eslint-disable-next-line
  }, [lastSurveyAllResults, benchType])

  const yearlyComp = {
    lastSurveyYear,
    selectedYear,
    benchType,
    benchmark: benchType === 'all' ? benchModAll : benchModCat,
    lastSurveyResults: benchType === 'all' ? lastSurveyAllResults : lastSurveyCatResults,
    selectedSurveyResults: benchType === 'all' ? selectSurveyAllResults : selectSurveyCatResults,
  }
  // console.log('yearlyComp:', yearlyComp)

  const getChartData = () => {
    // console.log('benchModAll:', benchModAll)
    const firstArgs = [
      jt.label,
      null, // only used for /results calls
      null,
      null,
      null,
      yearlyComp,
    ]
    switch (module) {
      case '1':
        return {
          first: getChartDataMod1(...firstArgs, 'summary'),
          second: getChartDataMod1(...firstArgs, CONSTRUCTS.FUTURE_ORIENTATION.name),
          third: getChartDataMod1(...firstArgs, CONSTRUCTS.ACTIVATION.name),
        }
      case '2':
        return {
          first: getChartDataMod2(...firstArgs, 'summary'),
        }
      case '3':
        return {
          first: getChartDataMod3(...firstArgs, 'summary'),
        }
      case '4':
        return {
          first: getChartDataMod4(...firstArgs, 'summary'),
        }
      case '5':
        return {
          first: getChartDataMod5(...firstArgs, 'summary'),
        }
      default:
        // Code to execute when none of the cases match
        console.log('getChartData() Switch: No matching value')
    }
  }

  const chartLabels = {
    first: jt.label?.mod1Overview || 'Module 1 Overview',
    second: jt.label?.[CONSTRUCTS.FUTURE_ORIENTATION.name] || 'Future Orientation',
    third: jt.label?.[CONSTRUCTS.ACTIVATION.name] || 'Future Orientation',
  }

  const chartsLoaded = module === '1' ? !!chartImages.third?.imgData : !!chartImages.first?.imgData
  // console.log('chartImages:', chartImages)

  return (
    <div>
      <ResultsHeader
        module={`module${module}`}
        chartsLoaded={chartsLoaded}
        chartImages={chartImages}
        benchModAll={null}
        benchModCat={null}
        modAllResults={null}
        modCatResults={null}
        constructs={CONSTRUCT_ARRAY[`M${module}`]}
        pdfReportUrl={pdfReportUrl}
        yearlyComp={yearlyComp}
      />

      <Container>
        <ChartAndNav
          chartData={chartData}
          chartLabels={chartLabels}
          type={module === '2' ? 'bar' : 'radar'}
          setChartImages={setChartImages}
          module={module}
          setModule={setModule}
          setChartData={setChartData}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          availableYears={availableYears}
          benchType={benchType}
          setBenchType={setBenchType}
        />
        {chartData &&
          <ResultsTable
            jtLabel={jt.label || {}}
            benchModAll={null}
            benchModCat={null}
            modAllResults={null}
            modCatResults={null}
            constructs={CONSTRUCT_ARRAY[`M${module}`]}
            yearlyComp={yearlyComp}
          />
        }

        <BookConsultationCTA />
      </Container>
      <Footer />
    </div>
  )
}

export default YearlyComparison

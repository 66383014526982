import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Image } from 'react-bootstrap'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { toast } from 'react-toastify'
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-icon.svg'
import model from '../../../assets/images/EO_Gesamtmodell_Grafik.png'
import CreatePDF from '../ReactPDF/CreatePDF'
import { formatDate } from '../../../utils/formatDate'
import { uploadPdfStoreAndEmail } from '../ResultsUtils/apiCallsForResults'
import styles from './ResultsHeader.module.scss'

/**
 * renders the picture and everything on it at the top of the results page
 * @param module, string, such as 'module1'
 * @param chartsLoaded, boolean, have all react-charts finished rendering? - for pdf-download link
 * @param chartImages, object, contains the image data after painting the charts to the canvas - for pdf-download
 * @param benchModAll, object, benchmark of all NPOs - for pdf-download
 * @param benchModCat, object, benchmark of NPOs of the current NPO category - for pdf-download
 * @param modAllResults, object, comparison of NPO survey to benchmark of all NPOs - for pdf-download
 * @param modCatResults, object, comparison of NPO survey to benchmark of NPOs of the current NPO category - for pdf-download
 * @param constructs, [object], constructs of current module - for pdf-download
 * @param feedbackTexts, object, constructs.short as keys, texts as values
 * @param pdfFileName, string
 * @param yearlyComp, object, containing all the info and data for a yearly comparison
 * @return {JSX.Element}
 * @constructor
 */
const ResultsHeader = ({
                         module, chartsLoaded, chartImages, benchModAll,  // here module is a string like 'module1'
                         benchModCat, modAllResults, modCatResults, constructs,
                         feedbackTexts, pdfReportUrl, yearlyComp,
                       }) => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)
  const orgID = user.orgID
  const orgName = user.orgName
  const [viewImage, setViewImage] = useState(false)
  const fileHasBeenUploaded = useRef({})

  // console.log('chartsLoaded:', chartsLoaded)

  const dateToday = formatDate(new Date(), 'YYYY.MM.DD')
  const fileNameTrunk = yearlyComp ?
    `${jt.header?.[module].replace(' ', '_') || `Module_${module.slice(-1)}`}`
    + `_${yearlyComp.lastSurveyYear}`
    + `${yearlyComp.selectedYear ? `-${yearlyComp.selectedYear}` : ''}`
    + `_${yearlyComp.benchType}`
    : (jt.label?.[`${module}ResultsFileName`] || `Module_${module.slice(-1)}_Results`)
  const pdfFileName = `${dateToday}_${fileNameTrunk}.pdf`
  const cloudinaryFileName = `${dateToday}_${fileNameTrunk}_${orgID}`

  // PDFDownloadLink handles the download to computer = first button click function.
  // This second function handles the upload to Cloudinary, storage in DB and email copy to Admin
  const secondButtonClickFunction = (loading, blob) => {
    if (!loading && blob && !fileHasBeenUploaded.results) {
      if (yearlyComp && !yearlyComp.selectedYear) return // if no comparison year has been selected, don't bother storing the PDF
      if (yearlyComp && fileHasBeenUploaded.current[yearlyComp.selectedYear]?.[yearlyComp.benchType]) return  // do not store twice
      const comparison = yearlyComp ?
        `${yearlyComp.lastSurveyYear}-${yearlyComp.selectedYear}-${yearlyComp.benchType}`
        : null
      const result = uploadPdfStoreAndEmail(orgID, orgName, module, blob, cloudinaryFileName, comparison)
      if (result) {
        if (yearlyComp) {  // the user can download the file as many times as they want, but it will only be stored once.
          if (!fileHasBeenUploaded.current[yearlyComp.selectedYear]) {
            fileHasBeenUploaded.current[yearlyComp.selectedYear] = {}
          }
          fileHasBeenUploaded.current[yearlyComp.selectedYear][yearlyComp.benchType] = true
        } else fileHasBeenUploaded.current.results = true
        toast.success(jt.toast?.yourPdfHasBeenSaved || 'Your PDF has been saved for further reference.')
      }
    }
  }

  const RenderPdfDownloadLink = () => {  // if PDF-file does not yet exist
    return (
      <PDFDownloadLink
        document={<CreatePDF
          jt={jt}
          module={module}
          images={chartImages}
          benchModAll={benchModAll}
          benchModCat={benchModCat}
          modAllResults={modAllResults}
          modCatResults={modCatResults}
          constructs={constructs}
          feedbackTexts={feedbackTexts}
          pdfFileName={pdfFileName}
          orgName={orgName}
          yearlyComp={yearlyComp}
        />}
        fileName={pdfFileName}
      >
        {({ blob, loading }) => {
          return (
            <button className={styles.mainBtn}
                    onClick={() => secondButtonClickFunction(loading, blob)}
            >
              <DownloadIcon className={styles.downloadIcon} alt='download icon' />
              {jt.label?.downloadPage || 'Download page'}
            </button>
          )
        }}
      </PDFDownloadLink>
    )
  }

  const RenderPreparingPdfFile = () => {  // temporary message while charts and PDF-file are being generated
    return (
      <span>
        {modAllResults &&  // only show if results data exists that can produce the PDF-file
          <button className={styles.mainBtn}>
            {jt.label?.preparingPdfFile || 'Preparing PDF-file...'}
          </button>
        }
      </span>
    )
  }

  const RenderDownloadFromCloudinary = () => {  // if PDF-file already exist
    return (
      <span>
        <button className={styles.mainBtn} onClick={() => window.open(pdfReportUrl, '_blank')}>
          {jt.label?.retrievePdfFile || 'Retrieve PDF-file'}
        </button>
      </span>
    )
  }

  return (
    <div>
      <div className={styles.pictureBackground}>
        {yearlyComp ?
          <>
            <h1>{jt.header?.comparisonWithPreviousYears || 'Comparison with previous years'}</h1>
            <p>{jt.block?.viewYourLatestResultsCompared || 'View your latest results compared with those of previous years'}.</p>
          </>
          :
          <>
            <h1>{jt.header?.yourSurveyResults || 'Your Survey Results'}</h1>
            <p>{jt.block?.seeYourResults || 'See your results...'}</p>
          </>
        }
        {pdfReportUrl ?
          <RenderDownloadFromCloudinary />
          :
          <>
            {chartsLoaded ?
              <RenderPdfDownloadLink />
              :
              <RenderPreparingPdfFile />
            }
          </>
        }
        {!yearlyComp && <button className={styles.secondBtn} onClick={() => setViewImage(!viewImage)}>
          {viewImage ? jt.label?.hideModelAgain || 'Hide the model again'
            : jt.label?.viewFullModel || 'View the full model'
          }
        </button>}
      </div>
      {viewImage && <Image src={model} className={styles.image} alt='Modell overview' />}
    </div>
  )
}
export default ResultsHeader

import { CONSTRUCTS } from '../../../utils/constructs'
import { getDataForChart } from '../../../utils/reactChartConfig'

/**
 * called by ResultsModule4.js
 * @param jtLabels, object, 'label' portion of the jsonText object from Redux
 * @param benchMod4All, object, benchmark of all organization categories
 * @param modBenchCatResults, object, comparison of user's organization category benchmark with respect to overall benchmark
 * @param mod4AllResults, object, the latest results of the user's organization with respect to overall benchmark
 * @param mod4CatResults, object, the latest results of the user's organization with respect to category benchmark
 * @param yearlyComp, object, containing all the info and data for a yearly comparison (or null)
 * @param type, string, either 'summary' (overall result) or the section name (not yet used)
 * @return object, the chart data for Module 4
 *
 * Note: for yearly comparison, we use the following variables slightly differently:
 * benchMod4All = benchmark, either for all NPOs ('all') or only the NPOs of the same category ('cat')
 * modBenchCatResults = last survey results
 * mod4AllResults = results of selected year's survey (or null)
 * mod4CatResults = always null
 */
export const getChartDataMod4 = (jtLabels, benchMod4All, modBenchCatResults, mod4AllResults, mod4CatResults, yearlyComp, type) => {
  if (yearlyComp) {
    benchMod4All = yearlyComp.benchmark
    modBenchCatResults = yearlyComp.lastSurveyResults
    mod4AllResults = yearlyComp.selectedSurveyResults
    mod4CatResults = null
  }
  let chartLabels, benchAllData, benchCatResultsData, allResultsData, catResultsData
  switch (type) {
    case 'summary':
      chartLabels = [  // the order here pertains only to the chart
        `${jtLabels[CONSTRUCTS.COMPLEXITY.name] || 'Complexity'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.FUNDING_OPPORTUNITY.name] || 'Funding Opportunities'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.HOSTILITY.name] || 'Hostility'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.LATITUDE.name] || 'Latitude'}`.toUpperCase(),
      ]
      benchAllData = [
        benchMod4All.KMP.stdDev === 0 ? 100 : 50,
        benchMod4All.MUN.stdDev === 0 ? 100 : 50,
        benchMod4All.HST.stdDev === 0 ? 100 : 50,
        benchMod4All.FRE.stdDev === 0 ? 100 : 50,
      ]
      benchCatResultsData = [
        modBenchCatResults?.KMP.percentile,
        modBenchCatResults?.MUN.percentile,
        modBenchCatResults?.HST.percentile,
        modBenchCatResults?.FRE.percentile,
      ]
      allResultsData = [
        mod4AllResults?.KMP.percentile,
        mod4AllResults?.MUN.percentile,
        mod4AllResults?.HST.percentile,
        mod4AllResults?.FRE.percentile,
      ]
      catResultsData = [
        mod4CatResults?.KMP.percentile,
        mod4CatResults?.MUN.percentile,
        mod4CatResults?.HST.percentile,
        mod4CatResults?.FRE.percentile,
      ]
      break
    default:
      console.error('No match found for chart type:', type)
  }

  return getDataForChart(jtLabels, chartLabels, benchAllData, benchCatResultsData, allResultsData, catResultsData, yearlyComp)
}

import axios from 'axios'
import { authHeaders } from '../../../utils/authHeaders'
import { handleStrapiAPIError } from '../../../utils/handleStrapiAPIError'
import { MIN_SAMPLE_SIZE } from '../../../utils/constants'
import { showNoCategoryBenchInfo } from '../../../utils/getMiscToastMsg'
import { calcModResult } from './calcModResults'
import { getFeedbackTexts } from '../../../utils/getFeedbackTexts'

const API_URL = process.env.REACT_APP_API_URL

/**
 * gets the current benchmark for all NPO categories and set the object in state
 * @param module, string, such as '1'
 * @param setBenchModAll, function, state setter
 * @return {Promise<void>}
 */
export const getBenchForAll = async (module, setBenchModAll) => {
  await axios
    .get(
      `${API_URL}/api/benchmarks` +
      `?filters[module][$eq]=${module}` +
      `&filters[orgCategory][$eq]=all`,
      authHeaders(),
    )
    .then(res => {
      const benchmarkModuleAll = res.data.data[0].attributes.benchmark
      setBenchModAll(benchmarkModuleAll)
    })
    .catch(error => handleStrapiAPIError(error))
}

/**
 * gets the current benchmark for the current NPO category and set the object in state
 * @param module, string, such as '1'
 * @param category, string, such as 'UmwNat'
 * @param jtToast, object, JSON Text with toast object
 * @param noCatBenchInfoHasBeenShown, useRef object,
 * @param setBenchModCat, function, state setter
 * @return {Promise<void>}
 */
export const getBenchForCat = async (module, category, jtToast = {}, noCatBenchInfoHasBeenShown, setBenchModCat) => {
  if (category) {
    await axios
      .get(
        `${API_URL}/api/benchmarks` +
        `?filters[module][$eq]=${module}` +
        `&filters[orgCategory][$eq]=${category}`,
        authHeaders(),
      )
      .then(res => {
        const benchModCatData = res.data.data[0].attributes.benchmark
        // console.log('benchModCatData:', benchModCatData)
        if (benchModCatData.sample_size >= MIN_SAMPLE_SIZE) {
          setBenchModCat(benchModCatData)
        } else {
          if (noCatBenchInfoHasBeenShown.current !== true) {
            showNoCategoryBenchInfo(jtToast)
            noCatBenchInfoHasBeenShown.current = true
          }
          setBenchModCat('Category not available')
        }
      })
      .catch(error => handleStrapiAPIError(error))
  } else setBenchModCat('Category not available')
}

/**
 * gets the NPO benchmark calculation results for current module and sets the objects in state
 * @param module, string, such as '1'
 * @param orgID, number, organization ID
 * @param jtResults, object, JSON text of the results
 * @param benchModAll, object, current overall Benchmark for module
 * @param benchModCat, object, current category Benchmark for module
 * @param setModAllResults, function, sets result object of NPO vs. overall Benchmark
 * @param setModCatResults, function, sets result object of NPO vs. category Benchmark
 * @param setModBenchCatResults, function, sets result object of category Benchmark vs. overall Benchmark
 * @param setModFeedbackTexts, function, produces { <three-letter-abbreviation>: '<text>', ... }
 * @param constructs, [object]
 * @param setPdfReportUrl, function, sets a string, either '' or containing the Cloudinary URL with the PDF-File
 * @return {Promise<void>}
 */
export const getSurveyAndReportData = async (
  module,
  orgID,
  jtResults = {},
  benchModAll,
  benchModCat,
  setModAllResults,
  setModCatResults,
  setModBenchCatResults,
  setModFeedbackTexts,
  constructs,
  setPdfReportUrl,
) => {
  await axios
    .get(
      `${API_URL}/api/npo-surveys` +
      `?filters[organizationID][$eq]=${orgID}` +
      `&filters[module][$eq]=${module}` +
      `&sort=createdAt:desc`,
      authHeaders(),
    )
    .then(res => {
      if (res.data.data.length === 0) return
      const survey = res.data.data[0].attributes
      const surveyDate = new Date(survey.createdAt).toISOString().split('T')[0] // formats to YYYY-MM-DD
      // console.log('surveyDate:', surveyDate)
      const answerObject = survey.npoAnswers
      const resultsAll = calcModResult(module, benchModAll, answerObject)  // NPO vs. overall Benchmark
      const resultsCat = benchModCat === 'Category not available' ? null
        : calcModResult(module, benchModCat, answerObject)                 // NPO vs. category Benchmark
      const resultsBenchCat = benchModCat === 'Category not available' ? null
        : calcModResult(module, benchModAll, benchModCat)                  // category Benchmark vs. overall Benchmark
      // console.log('resultsBenchCat:', resultsBenchCat)
      setModAllResults(resultsAll)
      setModCatResults(resultsCat)
      setModBenchCatResults(resultsBenchCat)
      const resultsForFB = resultsCat ? resultsCat : resultsAll
      setModFeedbackTexts(getFeedbackTexts(jtResults, resultsForFB, constructs))
      axios
        .get(
          `${API_URL}/api/pdf-reports` +
          `?filters[orgID][$eq]=${orgID}` +
          `&filters[module][$eq]=${module}` +
          `&filters[createdAt][$gte]=${surveyDate}` +
          `&filters[comparison][$null]=true` +
          `&sort=createdAt:desc`,
          authHeaders(),
        )
        .then(res => {
          // console.log('PDF-Report data:', res.data.data)
          if (res.data.data.length === 0) return
          setPdfReportUrl(res.data.data[0].attributes.cloudinaryURL)
        })
    })
    .catch(error => handleStrapiAPIError(error))
}

/**
 * called by YearlyComparison.js
 * gets the NPO benchmark calculation results for current module and selected years and sets the objects in state
 * @param module, string, such as '1'
 * @param orgID, number, organization ID
 * @param jtLabel, object, JSON text of the labels
 * @param benchModAll, object, current overall Benchmark for module
 * @param benchModCat, object, current category Benchmark for module
 * @param selectedYear, string, such as '2024'
 * @param setLastSurveyYear, function, from YearlyComparison.js, sets a string like '2024'
 * @param setAvailableYears, function, sets an array of strings, such as ['2023','2024']
 * @param setLastSurveyAllResults, function, sets result object of last survey vs. overall Benchmark
 * @param setLastSurveyCatResults, function, sets result object of last survey vs. category Benchmark
 * @param setSelectSurveyAllResults, function, sets result object of survey of selected year vs. overall Benchmark
 * @param setSelectSurveyCatResults, function, sets result object of survey of selected year vs. category Benchmark
 * @param constructs, [object]
 * @param benchType, string, either 'all' or 'cat'
 * @param setPdfReportUrl, function, sets a string, either '' or containing the Cloudinary URL with the PDF-File
 * @return {Promise<void>}
 */
export const getDataForYearlyComparison = async (
  module,
  orgID,
  jtLabel,
  benchModAll,
  benchModCat,
  selectedYear,
  setLastSurveyYear,
  setAvailableYears,
  setLastSurveyAllResults,
  setLastSurveyCatResults,
  setSelectSurveyAllResults,
  setSelectSurveyCatResults,
  constructs,
  benchType,
  setPdfReportUrl,
) => {
  await axios
    .get(
      `${API_URL}/api/npo-surveys` +
      `?filters[organizationID][$eq]=${orgID}` +
      `&filters[module][$eq]=${module}` +
      `&sort=createdAt:desc`,
      authHeaders(),
    )
    .then(res => {
      if (res.data.data.length === 0) return
      const surveys = res.data.data
      const lastSurvey = surveys[0].attributes
      const lastAnswerObject = lastSurvey.npoAnswers
      const lastSurveyYear = getYear(lastSurvey.createdAt) // formats to 'YYYY'
      setLastSurveyYear(lastSurveyYear)
      // console.log('lastSurveyYear:', lastSurveyYear)
      let selectedSurvey
      const surveyYears = [`${lastSurveyYear} (${jtLabel?.lastResultsOnly || 'last results only'})`]
      surveys.forEach((survey, idx) => {
        if (idx === 0) return // skip the last survey year
        const year = getYear(survey.attributes.createdAt) // formats to 'YYYY'
        if (year === selectedYear) selectedSurvey = survey  // we will need this later
        surveyYears.push(year)
      })
      // console.log('surveyYears:', surveyYears)
      setAvailableYears(surveyYears)
      let selectedAnswerObject
      if (selectedSurvey) {
        selectedAnswerObject = selectedSurvey.attributes.npoAnswers
      }
      const resultsLastAll = calcModResult(module, benchModAll, lastAnswerObject)  // last survey vs. overall Benchmark
      const resultsLastCat = benchModCat === 'Category not available' ? null
        : calcModResult(module, benchModCat, lastAnswerObject)                  // last survey vs. category Benchmark
      const resultsSelectAll = !selectedAnswerObject ? null
        : calcModResult(module, benchModAll, selectedAnswerObject)              // selected survey vs. overall Benchmark
      // console.log('benchModCat:', benchModCat)
      const resultsSelectCat = !selectedAnswerObject || benchModCat === 'Category not available' ? null
        : calcModResult(module, benchModCat, selectedAnswerObject)             // selected survey vs. category Benchmark
      // console.log('resultsBenchCat:', resultsBenchCat)
      setLastSurveyAllResults(resultsLastAll)
      setLastSurveyCatResults(resultsLastCat)
      setSelectSurveyAllResults(resultsSelectAll)
      setSelectSurveyCatResults(resultsSelectCat)
      if (selectedYear) getPdfComparisonUrl(module, orgID, lastSurveyYear, selectedYear, benchType, setPdfReportUrl)
    })
    .catch(error => handleStrapiAPIError(error))
}

const getYear = (date) => new Date(date).toISOString().split('-')[0] // formats to 'YYYY'

/**
 * Checks if a PDF already exists in the DB for this comparison, and if so, sets the corresponding URL string in state
 * @param module, string, such as '1'
 * @param orgID, number, organization ID
 * @param lastSurveyYear, string, such as '2024'
 * @param selectedYear, string, such as '2024'
 * @param benchType, string, either 'all' or 'cat'
 * @param setPdfReportUrl, function, sets a string, either '' or containing the Cloudinary URL with the PDF-File
 */
export const getPdfComparisonUrl = (module, orgID, lastSurveyYear, selectedYear, benchType, setPdfReportUrl) => {
  axios
    .get(
      `${API_URL}/api/pdf-reports` +
      `?filters[orgID][$eq]=${orgID}` +
      `&filters[module][$eq]=${module}` +
      `&filters[comparison][$eq]=${lastSurveyYear}-${selectedYear}-${benchType}` +
      `&sort=createdAt:desc`,
      authHeaders(),
    )
    .then(res => {
      // console.log('PDF-Report data:', res.data.data)
      const report = res.data.data[0]
      const url = report ? report.attributes.cloudinaryURL : ''
      setPdfReportUrl(url)
    })
    .catch(error => handleStrapiAPIError(error))
}

/**
 * sends the PDF to BE as a blob, where the following happens:
 * (1) the PDF is uploaded to Cloudinary
 * (2) the orgID, orgName, module, and Cloudinary URL are stored in the Strapi-DB
 * (3) a copy of the PDF is sent to the NPO-Benchmark administrator by email using Mailjet
 * @param orgID, integer
 * @param orgName, string
 * @param module, string, such as 'module1'
 * @param pdfBlob, blob
 * @param fileName, string, such as '2024.09.17_Ergebnisse_Kernmodul_24'
 * @param comparison, null|string, such as '2024-2022'
 * @return {Promise<void>}
 */
export const uploadPdfStoreAndEmail = async (orgID, orgName, module, pdfBlob, fileName, comparison) => {

  const formData = new FormData()
  formData.append('orgID', orgID)
  formData.append('orgName', orgName)
  formData.append('moduleNumber', module.slice(-1))
  formData.append('fileName', fileName)
  formData.append('comparison', comparison)
  formData.append('pdfBlob', pdfBlob, fileName) // Append the blob as 'pdfBlob'

  await axios
    .post(
      `${API_URL}/api/upload-pdf`,
      formData, // Strapi automatically separates this formData into request.body and request.files
      authHeaders(), // axios automatically adds the header 'content-type': 'multipart/form-data'
    )
    .then(res => {
      const result = res.data?.message
      console.log('uploadPdfStoreAndEmail() result:', result || 'Error in BE call to upload-PDF!')
      return result
    })
    .catch(error => handleStrapiAPIError(error))
}

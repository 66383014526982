import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Col, FormLabel, FormSelect, InputGroup, ListGroup, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js'
import { Radar, Bar } from 'react-chartjs-2'
import html2canvas from 'html2canvas'
import { getResByModArr } from '../../../utils/getResByModArr'
import { BAR_OPTIONS, barLegendMargin, RADAR_OPTIONS } from '../../../utils/reactChartConfig'
import styles from './ChartAndNave.module.scss'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
)

/**
 * Called by ResultsModule1-5.js and YearlyComparison.js
 * Renders the chart on the left and the navigation list on the right (or comparison selectors for the yearly comparison).
 * It also renders the charts directly below that where necessary.
 * @param chartData, object { first: {<data to create the first chart>}, second: {}... }
 * @param chartLabels, object, { first: <'label 1'>, second: <'label 2'>, third: <'label 3'> }
 * @param type, string, either 'radar' or 'bar'
 * @param setChartImage, state setter function, to set the chart image for the pdf-file
 * @param module, string such as '1', only for yearly comparison
 * @param setModule, state setter function for yearly comparison
 * @param setChartData, state setter function for yearly comparison to ensure the Results Table doesn't rerender until all is calculated and charts are redrawn
 * @param selectedYear, string such as '2024', only for yearly comparison
 * @param setSelectedYear, state setter function for yearly comparison
 * @param availableYears, array of strings, such as ['2023','2024'] for yearly comparison
 * @param benchType, string of either 'all' or 'cat', only for yearly comparison
 * @param setBenchType, state setter function for yearly comparison, sets to string of either 'all' or 'cat'
 * @return {JSX.Element}
 */
const ChartAndNav = ({
                       chartData,
                       chartLabels,
                       type,
                       setChartImages,
                       module,
                       setModule,
                       setChartData,
                       selectedYear,
                       setSelectedYear,
                       availableYears,
                       benchType,
                       setBenchType,
                     }) => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const chart1Ref = useRef(null)
  const chart2Ref = useRef(null)
  const chart3Ref = useRef(null)

  useEffect(() => {
    // create the image of the first chart for the downloadable pdf file,
    // if the data has arrived and there is a setChartImage function present
    if (chartData && chart1Ref.current && setChartImages) {
      genChartImage(chart1Ref, 'first')
    }
    // eslint-disable-next-line
  }, [chart1Ref.current, module, selectedYear, benchType])

  useEffect(() => {
    if (chartData && chart2Ref.current && setChartImages) {
      genChartImage(chart2Ref, 'second')
    }
    // eslint-disable-next-line
  }, [chart2Ref.current, module, selectedYear, benchType])

  useEffect(() => {
    if (chartData && chart3Ref.current && setChartImages) {
      genChartImage(chart3Ref, 'third')
    }
    // eslint-disable-next-line
  }, [chart3Ref.current, module, selectedYear, benchType])

  const genChartImage = (ref, ordinal) => {
    // something deep down in the chart generation needs time to complete
    // before the scales are correctly set and the image for the PDF is also complete
    const wait = module ? 900 : 700  // yearly comparison requires more time (if unfinished PDF charts are seen, increase this)
    setTimeout(() => html2canvas(ref.current).then(canvas => {
      const imgData = canvas.toDataURL('image/png')
      const imgWidth = 160 // A4 width = 210 mm; then subtract 1 inch=25mm from both sides
      const imgHeight = imgWidth * (canvas.height / canvas.width)
      setChartImages(prev => ({
        ...prev,
        [ordinal]: { imgData, imgWidth, imgHeight },
      }))
    }), wait)
  }

  const chartOptions = {}
  chartOptions.first = type === 'radar' ?
    JSON.parse(JSON.stringify(RADAR_OPTIONS)) // make a deep copy
    : JSON.parse(JSON.stringify(BAR_OPTIONS))
  chartOptions.first.plugins.title.text = chartLabels?.first

  chartOptions.second = type === 'radar' ?
    JSON.parse(JSON.stringify(RADAR_OPTIONS))
    : JSON.parse(JSON.stringify(BAR_OPTIONS))
  chartOptions.second.plugins.title.text = chartLabels?.second

  chartOptions.third = type === 'radar' ?
    JSON.parse(JSON.stringify(RADAR_OPTIONS))
    : JSON.parse(JSON.stringify(BAR_OPTIONS))
  chartOptions.third.plugins.title.text = chartLabels?.third

  // decrease legend font size on smaller screens
  if (window.innerWidth < 992) {
    chartOptions.first.plugins.legend.labels.font.size = 10
    chartOptions.second.plugins.legend.labels.font.size = 10
    chartOptions.third.plugins.legend.labels.font.size = 10
  } else if (window.innerWidth < 1200) {
    chartOptions.first.plugins.legend.labels.font.size = 14
    chartOptions.second.plugins.legend.labels.font.size = 14
    chartOptions.third.plugins.legend.labels.font.size = 14
  }

  const resultsByModArr = getResByModArr(jt.header)
  // console.log('resultsByModArr:',resultsByModArr)

  const RenderChart = ({ ordinal }) => {
    if (type === 'radar') {
      return <Radar data={chartData[ordinal]} options={chartOptions[ordinal]} className={styles.radar} />
    } else {
      return <Bar data={chartData[ordinal]} options={chartOptions[ordinal]} className={styles.bar}
                  plugins={[barLegendMargin]} />
    }
  }

  const RenderLinkGroup = () => {
    return (
      <>
        <div className={styles.yourResults}>
          {jt.header?.yourResults || 'Your Results'}
        </div>
        <ListGroup>
          <LinkContainer to={resultsByModArr[1].resultsLink} key={'module 1'}>
            <ListGroup.Item action className={styles.linkItem}
                            bsPrefix={'chartNavMod1Item'}
            >
              <div className={styles.moduleTitle}>
                {resultsByModArr[1].module && <span>{resultsByModArr[1].module}{': '}</span>}
                <br />
                {resultsByModArr[1].title.replace('-', '')}
              </div>
            </ListGroup.Item>
          </LinkContainer>
          <Row className={styles.verticalSection}>
            {resultsByModArr.map((mod, idx) => {
              if (idx < 2) return null // module 0 is not included; module 1 is rendered horizontally above
              // we need to use key={idx} so that react will not duplicate after getting all the correct header names
              return (
                <LinkContainer to={mod.resultsLink} key={idx}>
                  <ListGroup.Item action className={styles.vLinkItem}
                                  bsPrefix={`chartNavMod${idx}Item`}
                  >
                    <div className={styles.vModuleTitle}>
                      {mod.module && <span>{mod.module}{': '}</span>}
                      <br />
                      {mod.title.replace('-', '')}
                    </div>
                  </ListGroup.Item>
                </LinkContainer>
              )
            })}
          </Row>
        </ListGroup>
      </>
    )
  }

  const RenderComparisonSelectors = () => {
    return (
      <div className={styles.selectorSection}>
        <div className={styles.header}>
          {jt.block?.pleaseSelectScopeHere || 'Please select the scope of your comparison here'}:
        </div>
        <InputGroup className='my-4'>
          <InputGroup.Text>{jt.label?.module || 'Module'}:</InputGroup.Text>
          <FormSelect
            defaultValue={module}
            onChange={e => {
              setModule(e.target.value)
              setChartData(null) // ensure the Results Table doesn't rerender until all is calculated and charts are redrawn
              setChartImages({})
            }}
          >
            <option value='1'>{jt.header?.module1 || '1'}</option>
            <option value='2'>{jt.header?.module2 || '2'}</option>
            <option value='3'>{jt.header?.module3 || '3'}</option>
            <option value='4'>{jt.header?.module4 || '4'}</option>
            <option value='5'>{jt.header?.module5 || '5'}</option>
          </FormSelect>
        </InputGroup>
        <InputGroup className='my-4'>
          <InputGroup.Text>{jt.label?.previousYear || 'Previous Year'}:</InputGroup.Text>
          <FormSelect
            defaultValue={selectedYear}
            onChange={e => setSelectedYear(e.target.value)}
          >
            {availableYears.length && availableYears.map((yr, idx) => {
              return <option key={idx} value={yr}>{yr}</option>
            })}
          </FormSelect>
        </InputGroup>
        <FormLabel className={styles.benchScope}>{jt.label?.benchmarkScope || 'Benchmark Scope'}:</FormLabel>
        <FormSelect
          defaultValue={benchType}
          onChange={e => setBenchType(e.target.value)}
          style={{ borderTopLeftRadius: 0 }}
        >
          <option value='all'>{jt.label?.benchmarkAllNPOs || 'Benchmark of all NPOs'}</option>
          <option value='cat'>{jt.label?.benchmarkNPOsOfSameField || 'Benchmark of NPOs of same field'}</option>
        </FormSelect>
      </div>
    )
  }

  return (
    <div>
      <Row>
        <Col xs={6} className={styles.leftCol}>
          {chartData?.first ? (
            <div ref={chart1Ref}>
              <RenderChart ordinal={'first'} />
            </div>
          ) : (
            <div className={styles.radar}>
              <p className={styles.noDataTitle}>
                {jt.block?.yourNpoHasNotYetCompletedASurvey ||
                  'Your NPO has not yet completed a full survey and therefore no results can be shown.\nIf in doubt, see the Survey Administrator of your NPO.'}
              </p>
            </div>
          )}
        </Col>
        <Col xs={6} className={styles.rightCol}>
          {module ?                         // only passed down by YearlyComparison.js
            <RenderComparisonSelectors />
            :
            <RenderLinkGroup />
          }
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          {chartData?.second &&
            <div ref={chart2Ref}>
              <RenderChart ordinal={'second'} />
            </div>
          }
        </Col>
        <Col>
          {chartData?.third &&
            <div ref={chart3Ref}>
              <RenderChart ordinal={'third'} />
            </div>
          }
        </Col>
      </Row>
    </div>
  )
}

export default ChartAndNav

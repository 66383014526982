import { getDataForChart } from '../../../utils/reactChartConfig'
import { CONSTRUCTS } from '../../../utils/constructs'

/**
 * called by ResultsModule1.js and YearlyComparison.js
 * @param jtLabels, object, 'label' portion of the jsonText object from Redux
 * @param benchMod1All, object, benchmark of all organization categories
 * @param modBenchCatResults, object, comparison of user's organization category benchmark with respect to overall benchmark
 * @param mod1AllResults, object, the latest results of the user's organization with respect to overall benchmark
 * @param mod1CatResults, object, the latest results of the user's organization with respect to category benchmark
 * @param yearlyComp, object, containing all the info and data for a yearly comparison (or null)
 * @param type, string, either 'summary' (overall result) or the section name ('activation' or 'future orientation')
 * @return object, the chart data for Module 1
 *
 * Note: for yearly comparison, we use the following variables slightly differently:
 * benchMod1All = benchmark, either for all NPOs ('all') or only the NPOs of the same category ('cat')
 * modBenchCatResults = last survey results
 * mod1AllResults = results of selected year's survey (or null)
 * mod1CatResults = always null
 */
export const getChartDataMod1 = (jtLabels, benchMod1All, modBenchCatResults, mod1AllResults, mod1CatResults, yearlyComp, type) => {
  // console.log('modBenchCatResults:', modBenchCatResults)
  if (yearlyComp) {
    benchMod1All = yearlyComp.benchmark
    modBenchCatResults = yearlyComp.lastSurveyResults
    mod1AllResults = yearlyComp.selectedSurveyResults
    mod1CatResults = null
  }
  let chartLabels, benchAllData, benchCatResultsData, allResultsData, catResultsData
  switch (type) {
    case 'summary':
      chartLabels = [  // the order here pertains only to the chart
        `${jtLabels[CONSTRUCTS.ENTREPRENEURIAL_ORIENTATION.name] || 'Entrepreneurial Orientation'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.ACTIVATION.name] || 'Activation'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.FUTURE_ORIENTATION.name] || 'Future Orientation'}`.toUpperCase(),
      ]
      benchAllData = [
        benchMod1All.UFK.stdDev === 0 ? 100 : 50,
        benchMod1All.AKT.stdDev === 0 ? 100 : 50,
        benchMod1All.ZKO.stdDev === 0 ? 100 : 50,
      ]
      benchCatResultsData = [
        modBenchCatResults?.UFK.percentile,
        modBenchCatResults?.AKT.percentile,
        modBenchCatResults?.ZKO.percentile,
      ]
      allResultsData = [
        mod1AllResults?.UFK.percentile,
        mod1AllResults?.AKT.percentile,
        mod1AllResults?.ZKO.percentile,
      ]
      catResultsData = [
        mod1CatResults?.UFK.percentile,
        mod1CatResults?.AKT.percentile,
        mod1CatResults?.ZKO.percentile,
      ]
      break
    case CONSTRUCTS.ACTIVATION.name:
      chartLabels = [
        `${jtLabels[CONSTRUCTS.AUTONOMY.name] || 'Autonomy'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.COMMUNITY_MOBILIZATION.name] || 'Community Mobilization'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.COOPERATIVE_MOBILIZATION.name] || 'Cooperative Mobilization'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.AGGRESSIVENESS.name] || 'Aggressiveness'}`.toUpperCase(),
      ]
      benchAllData = [
        benchMod1All.AUT.stdDev === 0 ? 100 : 50,
        benchMod1All.GEM.stdDev === 0 ? 100 : 50,
        benchMod1All.KOP.stdDev === 0 ? 100 : 50,
        benchMod1All.AGR.stdDev === 0 ? 100 : 50,
      ]
      benchCatResultsData = [
        modBenchCatResults?.AUT.percentile,
        modBenchCatResults?.GEM.percentile,
        modBenchCatResults?.KOP.percentile,
        modBenchCatResults?.AGR.percentile,
      ]
      allResultsData = [
        mod1AllResults?.AUT.percentile,
        mod1AllResults?.GEM.percentile,
        mod1AllResults?.KOP.percentile,
        mod1AllResults?.AGR.percentile,
      ]
      catResultsData = [
        mod1CatResults?.AUT.percentile,
        mod1CatResults?.GEM.percentile,
        mod1CatResults?.KOP.percentile,
        mod1CatResults?.AGR.percentile,
      ]
      break
    case CONSTRUCTS.FUTURE_ORIENTATION.name:
      chartLabels = [
        `${jtLabels[CONSTRUCTS.PROACTIVITY.name] || 'Proactivity'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.INNOVATION.name] || 'Innovation'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.RISK_TAKING.name] || 'Risk-taking'}`.toUpperCase(),
      ]
      benchAllData = [
        benchMod1All.PRO.stdDev === 0 ? 100 : 50,
        benchMod1All.INN.stdDev === 0 ? 100 : 50,
        benchMod1All.RSK.stdDev === 0 ? 100 : 50,
      ]
      benchCatResultsData = [
        modBenchCatResults?.PRO.percentile,
        modBenchCatResults?.INN.percentile,
        modBenchCatResults?.RSK.percentile,
      ]
      allResultsData = [
        mod1AllResults?.PRO.percentile,
        mod1AllResults?.INN.percentile,
        mod1AllResults?.RSK.percentile,
      ]
      catResultsData = [
        mod1CatResults?.PRO.percentile,
        mod1CatResults?.INN.percentile,
        mod1CatResults?.RSK.percentile,
      ]
      break
    default:
      console.error('No match found for chart type:', type)
  }

  return getDataForChart(jtLabels, chartLabels, benchAllData, benchCatResultsData, allResultsData, catResultsData, yearlyComp)
}

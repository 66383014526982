import { useSelector } from 'react-redux'
import { CONSTRUCTS } from '../../../utils/constructs'
import styles from './ResultsText.module.scss'

const MODULE_CONSTRUCT_SHORTS = [ // use to avoid repeating Module descriptions
  CONSTRUCTS.ENTREPRENEURIAL_ORIENTATION.short,
]

/**
 * Renders the text below the table
 * @param module, string such as 'module1'
 * @param constructs, array of construct objects { name: <name>, short: <three-letter-abbreviation> }
 * @param modAllResults, object { <construct short>: { mean, percentile } } for all NPOs
 * @param modCatResults, object { <construct short>: { mean, percentile } } for NPOs of same category
 * @param feedbackTexts, object { <construct short>: "text" }
 * @return {JSX.Element}
 */
const ResultsText = ({ module, constructs, modAllResults, modCatResults, feedbackTexts }) => {
  const jt = useSelector(state => state.jsonText.jsonText)
  return (
    <div className={styles.resultsText}>
      <h2 className={styles.paragraphTitle}>
        {jt.header?.[`${module}Title`].replace('-', '') || 'Module title loading...'}
      </h2>

      <p className={styles.paragraphText}>
        {modAllResults ?
          <>
            {jt.block?.[`${module}Explanation`] || 'Module explanation loading...'}
          </>
          :
          <>
            {jt.block?.allQuestionsMustBeAnswered || 'All questions must be answered'}
            <br />
            {jt.block?.[`${module}AfterAnswering`] || 'After answering, you\'ll see your results'}
          </>
        }
      </p>

      {modAllResults && constructs.map(construct => {
        // skip the module description already handled above
        if (MODULE_CONSTRUCT_SHORTS.includes(construct.short)) return null
        return (
          <div key={construct.short}>
            <h2 className={styles.constructTitle}>
              {jt.label?.[construct.name] || 'construct name loading...'}
            </h2>
            <div className={styles.paragraphText}>
              <div className={styles.top}>
                <p>
                  {jt.block?.[`${construct.name}Definition`] || 'Construct definition loading...'}
                </p>
                <p>
                  {jt.block?.[`${construct.name}Usefulness`] || 'Discussion of this construct loading...'}
                </p>
              </div>
              <p className={styles.noWrap}>
                {jt.label?.yourPercentileAllNPOs || 'Your percentile in relation to all NPOs'}
                {': '}
                <span className={styles.bold}>
                    {modAllResults[construct.short].percentile}%
                </span>
                <br />
                {jt.label?.yourPercentileNPOsSameField || 'Your percentile in relation to NPOs in the same field'}
                {': '}
                <span className={styles.bold}>
                    {modCatResults?.[construct.short].percentile ?? '-'}%
                </span>
              </p>
              <p>
                {feedbackTexts[construct.short] || `This result indicates that you ...loading...`}
              </p>
            </div>
          </div>
        )
      })}

    </div>
  )
}

export default ResultsText

import React from 'react'
import { Table } from 'react-bootstrap'
import { roundToPlace } from '../../../utils/statisticCalc'
import { CONSTRUCTS } from '../../../utils/constructs'
import styles from './ResultsTable.module.scss'

const modules = [ // module name for summary at end of table
  CONSTRUCTS.ENTREPRENEURIAL_ORIENTATION.name,
]
const sections = [ // main sub-modules or sections
  CONSTRUCTS.ACTIVATION.name,
  CONSTRUCTS.FUTURE_ORIENTATION.name,
]
const preSections = [  // construct right before the above section construct to darken border-bottom-color
  CONSTRUCTS.AGGRESSIVENESS.name,
  CONSTRUCTS.RISK_TAKING.name,
]

/**
 * Renders the table of the results from each Results page
 * @param jtLabel, object, 'label' portion of the jsonText object from Redux
 * @param benchModAll, object
 * @param benchModCat, object
 * @param modAllResults, object
 * @param modCatResults, object
 * @param constructs, [object] { name: <name>, short: <three-letter-abbreviation> }
 * @param yearlyComp, object, containing all the info and data for a yearly comparison
 * @return {JSX.Element}
 *
 * Note: for yearly comparison, we use the following variables slightly differently:
 * benchModAll = benchmark, either for all NPOs ('all') or only the NPOs of the same category ('cat')
 * benchModCat = always null
 * modAllResults = last survey results
 * modCatResults = results of selected year's survey (or null)
 */
function ResultsTable({ jtLabel, benchModAll, benchModCat, modAllResults, modCatResults, constructs, yearlyComp }) {

  const showModCatResults = modCatResults && !yearlyComp

  let yourPercentileText
  if (yearlyComp) {
    benchModAll = yearlyComp.benchmark
    benchModCat = null
    modAllResults = yearlyComp.lastSurveyResults
    modCatResults = yearlyComp.selectedSurveyResults
    yourPercentileText = yearlyComp.benchType === 'all' ?
      jtLabel.yourPercentileAllNPOs || '% Rank - All NPOs'
      : jtLabel.yourPercentileNPOsSameField || '% Rank - NPOs Same Field'
  }

  const getTrClassName = (constructName) => {
    if (modules.includes(constructName)) return styles.module
    if (sections.includes(constructName)) return styles.section
    if (preSections.includes(constructName)) return styles.preSection
    return styles.tableData
  }

  return (
    <Table bordered striped className={styles.table}>
      <thead className={styles.tableHead}>
      <tr>
        <th rowSpan={2}>
          {jtLabel.construct || 'Construct'}
        </th>
        <th colSpan={2}>
          {yearlyComp?.benchType === 'cat' ?
            jtLabel.benchmarkNPOsOfSameField || 'Benchmark of NPOs in same field'
            : jtLabel.benchmarkAllNPOs || 'Benchmark of all NPOs'
          }
        </th>
        {showModCatResults &&
          <th colSpan={2}>
            {jtLabel.benchmarkNPOsOfSameField || 'Benchmark of NPOs in same field'}
          </th>
        }
        <th colSpan={showModCatResults ? 3 : 2}>
          {jtLabel.yourNpoResults || 'Your NPO Results'} {yearlyComp && yearlyComp.lastSurveyYear}
        </th>
        {yearlyComp?.selectedSurveyResults &&
          <th colSpan={2}>
            {jtLabel.yourNpoResults || 'Your NPO Results'} {yearlyComp && yearlyComp.selectedYear}
          </th>
        }
      </tr>
      <tr className={styles.tableHeadBottomRow}>
        <th>{jtLabel.mean || 'Mean'}</th>
        <th>{jtLabel.standardDeviation || 'Std. Dev.'}</th>
        {showModCatResults && <>
          <th>{jtLabel.mean || 'Mean'}</th>
          <th>{jtLabel.standardDeviation || 'Std. Dev.'}</th>
        </>}
        <th>{jtLabel.mean || 'Mean'}</th>
        <th>
          {yearlyComp ? yourPercentileText
            : jtLabel.yourPercentileAllNPOs || '% Rank - All NPOs'
          }
        </th>
        {showModCatResults && <th>{jtLabel.yourPercentileNPOsSameField || '% Rank - NPOs Same Field'}</th>}
        {yearlyComp?.selectedSurveyResults && <>
          <th>{jtLabel.mean || 'Mean'}</th>
          <th>{yourPercentileText}</th>
        </>}
      </tr>
      </thead>
      <tbody className={styles.tableBody}>
      {modAllResults && Object.keys(modAllResults).map(key => {
        // console.log('Object.keys(modAllResults):', Object.keys(modAllResults))
        const construct = constructs.find(construct => construct.short === key)
        return <tr key={key} className={getTrClassName(construct.name)}>
          <td>{jtLabel[construct.name] || 'Waiting to get Construct...'}</td>
          <td>{benchModAll && roundToPlace(benchModAll[construct.short].mean, 1)}</td>
          <td>{benchModAll && roundToPlace(benchModAll[construct.short].stdDev, 1)}</td>
          {showModCatResults && <>
            <td>{benchModCat && roundToPlace(benchModCat[construct.short].mean, 1)}</td>
            <td>{benchModCat && roundToPlace(benchModCat[construct.short].stdDev, 1)}</td>
          </>}
          <td>{modAllResults && roundToPlace(modAllResults[construct.short].mean, 1)}</td>
          <td>{modAllResults && roundToPlace(modAllResults[construct.short].percentile, 1)}</td>
          {showModCatResults && <td>{roundToPlace(modCatResults[construct.short].percentile, 1)}</td>}
          {yearlyComp?.selectedSurveyResults && <>
            <td>{roundToPlace(modCatResults[construct.short].mean, 1)}</td>
            <td>{roundToPlace(modCatResults[construct.short].percentile, 1)}</td>
          </>}
        </tr>
      })}
      </tbody>
    </Table>
  )
}

export default ResultsTable

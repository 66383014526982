import React from 'react'
import { Document, Font, Page, StyleSheet } from '@react-pdf/renderer'
import BarlowLight from '../../../assets/fonts/Barlow-Light.ttf'
import BarlowMedium from '../../../assets/fonts/Barlow-Medium.ttf'
import BarlowRegular from '../../../assets/fonts/Barlow-Regular.ttf'
import BarlowSemiBold from '../../../assets/fonts/Barlow-SemiBold.ttf'
import PdfCharts from './PdfCharts'
import PdfConstructs from './PdfConstructs'
import PdfContact from './PdfContact'
import PdfFooter from './PdfFooter'
import PdfHeader from './PdfHeader'
import PdfTable from './PdfTable'
import PdfTop from './PdfTop'

Font.register({
  family: 'Barlow', fonts: [
    { src: BarlowLight, fontStyle: 'normal', fontWeight: 300 },
    { src: BarlowRegular, fontStyle: 'normal', fontWeight: 400 },
    { src: BarlowMedium, fontStyle: 'normal', fontWeight: 500 },
    { src: BarlowSemiBold, fontStyle: 'normal', fontWeight: 600 },
  ],
})

const styles = StyleSheet.create({
  page: {
    padding: '80px 30px 60px',
    fontFamily: 'Barlow',
  },
})

const CreatePDF = ({
                     jt, // jsonText object
                     module, // string such as 'module1'
                     images, // object { first: { imgData, imgWidth, imgHeight }, second:...}
                     benchModAll, // object, benchmark for current module, all categories
                     benchModCat, // object, benchmark for current module, user's org category
                     modAllResults, // object, results for current module, based on overall module benchmark
                     modCatResults, // object, results for current module, based on module benchmark for user's org category
                     constructs, // array of objects, { name: <name>, short: <three-letter-abbreviation> }
                     feedbackTexts, // object { [<three-letter-abbreviation>]: <feedback text> }
                     pdfFileName, // string, such as '2024.06.18_Ergebnisse_Modul_1.pdf'
                     orgName, // string
                     yearlyComp, // object containing info and data for yearly comparisons
                   }) => (
  <Document>
    <Page size='A4' style={styles.page}>
      <PdfHeader jtLabel={jt.label || {}} />
      {/* Title and top text */}
      <PdfTop jt={jt || {}} module={module} orgName={orgName} yearlyComp={yearlyComp} />
      {/* module is passed to Charts, Table, Constructs and Contact to enable page-breaking where necessary*/}
      <PdfCharts images={images} />
      <PdfTable
        jtLabel={jt.label || {}}
        benchModAll={benchModAll}
        benchModCat={benchModCat}
        modAllResults={modAllResults}
        modCatResults={modCatResults}
        constructs={constructs}
        yearlyComp={yearlyComp}
      />
      {/* Constructs and explanations */}
      {!yearlyComp && <PdfConstructs
        jt={jt || {}}
        constructs={constructs}
        modAllResults={modAllResults}
        modCatResults={modCatResults}
        feedbackTexts={feedbackTexts}
      />}
      <PdfContact jt={jt || {}} module={module} yearlyComp={!!yearlyComp} />
      <PdfFooter pdfFileName={pdfFileName} />
    </Page>
  </Document>
)


export default CreatePDF

import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import BookConsultationCTA from '../common/BookConsultationCTA/BookConsultationCTA'
import ChartAndNav from './ChartAndNav/ChartAndNav'
import Footer from '../Footer/Footer'
import ResultsHeader from './ResultsHeader/ResultsHeader'
import ResultsTable from './ResultsTable/ResultsTable'
import ResultsText from './ResultsText/ResultsText'
import { getChartDataMod5 } from './ResultsUtils/getChartDataMod5'
import { getBenchForAll, getBenchForCat, getSurveyAndReportData } from './ResultsUtils/apiCallsForResults'
import { CONSTRUCT_ARRAY } from '../../utils/constructs'

// render the Module 5 (Step 6) survey results; called by Results.js
const ResultsModule5 = () => {
  const jt = useSelector(state => state.jsonText.jsonText)
  const user = useSelector(state => state.user.user)
  const [benchModAll, setBenchModAll] = useState(null)
  const [benchModCat, setBenchModCat] = useState(null)
  const [modAllResults, setModAllResults] = useState(null)
  const [modCatResults, setModCatResults] = useState(null)
  const [modBenchCatResults, setModBenchCatResults] = useState(null)  // NPO category compared with all NPOs
  const [modFeedbackTexts, setModFeedbackTexts] = useState({})
  const [chartImages, setChartImages] = useState({})
  const [pdfReportUrl, setPdfReportUrl] = useState('')
  const noCatBenchInfoHasBeenShown = useRef(null)

  useEffect(() => {
    // get latest benchmark
    if (user.strapiJwt && jt.toast) {
      // console.log('user:', user)
      if (!benchModAll) { // only run this once
        getBenchForAll('5', setBenchModAll).then()
      }
      if (!benchModCat) { // only run this once
        getBenchForCat('5', user.orgCategory, jt.toast, noCatBenchInfoHasBeenShown, setBenchModCat).then()
      }
      // get the latest answers for this user if they have fully completed this module
      if (benchModAll && benchModCat) {
        getSurveyAndReportData(
          '5',
          user.orgID,
          jt.results,
          benchModAll,
          benchModCat,
          setModAllResults,
          setModCatResults,
          setModBenchCatResults,
          setModFeedbackTexts,
          CONSTRUCT_ARRAY.M5,
          setPdfReportUrl,
        ).then()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jt, user, benchModAll, benchModCat])

  const chartData = modAllResults && benchModAll ?
    {
      first: getChartDataMod5(
        jt.label,
        benchModAll,
        modBenchCatResults,
        modAllResults,
        modCatResults,
        null,
        'summary',
      ),
    }
    : null

  const chartLabels = {
    first: jt.label?.mod5Overview || 'Module 5 Overview',
  }

  return (
    <div>
      <ResultsHeader
        module={'module5'}
        chartsLoaded={!!chartImages.first?.imgData}
        chartImages={chartImages}
        benchModAll={benchModAll}
        benchModCat={benchModCat}
        modAllResults={modAllResults}
        modCatResults={modCatResults}
        constructs={CONSTRUCT_ARRAY.M5}
        feedbackTexts={modFeedbackTexts}
        pdfReportUrl={pdfReportUrl}
      />

      <Container>
        <ChartAndNav
          chartData={chartData}
          chartLabels={chartLabels}
          type={'radar'}
          setChartImages={setChartImages}
        />
        {chartData &&
          <ResultsTable
            jtLabel={jt.label || {}}
            benchModAll={benchModAll}
            benchModCat={benchModCat}
            modAllResults={modAllResults}
            modCatResults={modCatResults}
            constructs={CONSTRUCT_ARRAY.M5}
          />
        }
        <ResultsText
          module={'module5'}
          constructs={CONSTRUCT_ARRAY.M5}
          modAllResults={modAllResults}
          modCatResults={modCatResults}
          feedbackTexts={modFeedbackTexts}
        />

        <BookConsultationCTA />
      </Container>
      <Footer />
    </div>
  )
}

export default ResultsModule5

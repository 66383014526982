import { getDataForChart } from '../../../utils/reactChartConfig'
import { CONSTRUCTS } from '../../../utils/constructs'

/**
 * called by ResultsModule3.js
 * @param jtLabels, object, 'label' portion of the jsonText object from Redux
 * @param benchMod3All, object, benchmark of all organization categories
 * @param modBenchCatResults, object, comparison of user's organization category benchmark with respect to overall benchmark
 * @param mod3AllResults, object, the latest results of the user's organization with respect to overall benchmark
 * @param mod3CatResults, object, the latest results of the user's organization with respect to category benchmark
 * @param yearlyComp, object, containing all the info and data for a yearly comparison (or null)
 * @param type, string, either 'summary' (overall result) or the section name (not yet used)
 * @return object, the chart data for Module 3
 *
 * Note: for yearly comparison, we use the following variables slightly differently:
 * benchMod3All = benchmark, either for all NPOs ('all') or only the NPOs of the same category ('cat')
 * modBenchCatResults = last survey results
 * mod3AllResults = results of selected year's survey (or null)
 * mod3CatResults = always null
 */
export const getChartDataMod3 = (jtLabels, benchMod3All, modBenchCatResults, mod3AllResults, mod3CatResults, yearlyComp, type) => {
  if (yearlyComp) {
    benchMod3All = yearlyComp.benchmark
    modBenchCatResults = yearlyComp.lastSurveyResults
    mod3AllResults = yearlyComp.selectedSurveyResults
    mod3CatResults = null
  }
  let chartLabels, benchAllData, benchCatResultsData, allResultsData, catResultsData
  switch (type) {
    case 'summary':
      chartLabels = [  // the order here pertains only to the chart
        `${jtLabels[CONSTRUCTS.CONSENSUS.name] || 'Consensus'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.STRATEGY.name] || 'Strategy'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.COMMUNICATION.name] || 'Communication'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.HR_DIVERSITY.name] || 'hrDiversity'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.HR_LOYALTY.name] || 'hrLoyalty'}`.toUpperCase(),
      ]
      benchAllData = [
        benchMod3All.KON.stdDev === 0 ? 100 : 50,
        benchMod3All.STR.stdDev === 0 ? 100 : 50,
        benchMod3All.IKM.stdDev === 0 ? 100 : 50,
        benchMod3All.HRD.stdDev === 0 ? 100 : 50,
        benchMod3All.HRB.stdDev === 0 ? 100 : 50,
      ]
      benchCatResultsData = [
        modBenchCatResults?.KON.percentile,
        modBenchCatResults?.STR.percentile,
        modBenchCatResults?.IKM.percentile,
        modBenchCatResults?.HRD.percentile,
        modBenchCatResults?.HRB.percentile,
      ]
      allResultsData = [
        mod3AllResults?.KON.percentile,
        mod3AllResults?.STR.percentile,
        mod3AllResults?.IKM.percentile,
        mod3AllResults?.HRD.percentile,
        mod3AllResults?.HRB.percentile,
      ]
      catResultsData = [
        mod3CatResults?.KON.percentile,
        mod3CatResults?.STR.percentile,
        mod3CatResults?.IKM.percentile,
        mod3CatResults?.HRD.percentile,
        mod3CatResults?.HRB.percentile,
      ]
      break
    default:
      console.error('No match found for chart type:', type)
  }

  return getDataForChart(jtLabels, chartLabels, benchAllData, benchCatResultsData, allResultsData, catResultsData, yearlyComp)
}

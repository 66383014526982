import { getDataForChart } from '../../../utils/reactChartConfig'
import { CONSTRUCTS } from '../../../utils/constructs'

/**
 * called by ResultsModule2.js
 * @param jtLabels, object, 'label' portion of the jsonText object from Redux
 * @param benchMod2All, object, benchmark of all organization categories
 * @param modBenchCatResults, object, comparison of user's organization category benchmark with respect to overall benchmark
 * @param mod2AllResults, object, the latest results of the user's organization with respect to overall benchmark
 * @param mod2CatResults, object, the latest results of the user's organization with respect to category benchmark
 * @param yearlyComp, object, containing all the info and data for a yearly comparison (or null)
 * @param type, string, either 'summary' (overall result) or the section name (not yet used)
 * @return object, the chart data for Module 2
 *
 * Note: for yearly comparison, we use the following variables slightly differently:
 * benchMod2All = benchmark, either for all NPOs ('all') or only the NPOs of the same category ('cat')
 * modBenchCatResults = last survey results
 * mod2AllResults = results of selected year's survey (or null)
 * mod2CatResults = always null
 */
export const getChartDataMod2 = (jtLabels, benchMod2All, modBenchCatResults, mod2AllResults, mod2CatResults, yearlyComp, type) => {
  if (yearlyComp) {
    benchMod2All = yearlyComp.benchmark
    modBenchCatResults = yearlyComp.lastSurveyResults
    mod2AllResults = yearlyComp.selectedSurveyResults
    mod2CatResults = null
  }
  let chartLabels, benchAllData, benchCatResultsData, allResultsData, catResultsData
  switch (type) {
    case 'summary':
      chartLabels = [  // the order here pertains only to the chart
        `${jtLabels[CONSTRUCTS.VISIONARY_LEADERSHIP.name] || 'Visionary Leadership'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.SERVANT_LEADERSHIP.name] || 'Servant Leadership'}`.toUpperCase(),
      ]
      benchAllData = [
        benchMod2All.MLQ.stdDev === 0 ? 100 : 50,
        benchMod2All.SVL.stdDev === 0 ? 100 : 50,
      ]
      benchCatResultsData = [
        modBenchCatResults?.MLQ.percentile,
        modBenchCatResults?.SVL.percentile,
      ]
      allResultsData = [
        mod2AllResults?.MLQ.percentile,
        mod2AllResults?.SVL.percentile,
      ]
      catResultsData = [
        mod2CatResults?.MLQ.percentile,
        mod2CatResults?.SVL.percentile,
      ]
      break
    default:
      console.error('No match found for chart type:', type)
  }

  return getDataForChart(jtLabels, chartLabels, benchAllData, benchCatResultsData, allResultsData, catResultsData, yearlyComp)
}

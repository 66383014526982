export const CONSTRUCTS = {
  // Module 1
  ACTIVATION: { name: 'activation', short: 'AKT' },
  AGGRESSIVENESS: { name: 'aggressiveness', short: 'AGR' },
  AUTONOMY: { name: 'autonomy', short: 'AUT' },
  COMMUNITY_MOBILIZATION: { name: 'communityMobilization', short: 'GEM' }, // 'Gemeinschaftliche Mobilisierung'
  // INTEREST_GROUP_MOBILIZATION: { name: 'interestGroupMobilization', short: 'GMI' }, // 'Gemeinschaftliche Mobilisierung in der Interessenvertretung'
  COOPERATIVE_MOBILIZATION: { name: 'cooperativeMobilization', short: 'KOP' }, // 'Kooperative Mobilisierung'
  ENTREPRENEURIAL_ORIENTATION: { name: 'entrepreneurialOrientation', short: 'UFK' }, // 'Unternehmerische Führung und Kultur'
  FUTURE_ORIENTATION: { name: 'futureOrientation', short: 'ZKO' }, // 'Zukunftsorientierung'
  INNOVATION: { name: 'innovation', short: 'INN' },
  PROACTIVITY: { name: 'proactivity', short: 'PRO' },
  // INTEREST_GROUP_PROACTIVITY: { name: 'interestGroupProactivity', short: 'PIV' }, // 'Proaktivität in der Interessenvertretung'
  RISK_TAKING: { name: 'riskTaking', short: 'RSK' },
  // Module 2
  VISIONARY_LEADERSHIP: { name: 'visionaryLeadership', short: 'MLQ' },
  SERVANT_LEADERSHIP: { name: 'servantLeadership', short: 'SVL' },
  // Module 3
  CONSENSUS: { name: 'consensus', short: 'KON' },
  STRATEGY: { name: 'strategy', short: 'STR' },
  COMMUNICATION: { name: 'communication', short: 'IKM' }, // 'Interne Kommunikation'
  HR_DIVERSITY: { name: 'hrDiversity', short: 'HRD' },
  HR_LOYALTY: { name: 'hrLoyalty', short: 'HRB' },
  // Module 4
  COMPLEXITY: { name: 'complexity', short: 'KMP' },
  FUNDING_OPPORTUNITY: { name: 'fundingOpportunity', short: 'MUN' }, // 'Munifizienz'
  HOSTILITY: { name: 'hostility', short: 'HST' },
  LATITUDE: { name: 'latitude', short: 'FRE' }, // 'Freiräume in der Leistungserbringung'
  // Module 5
  SOCIAL_MISSION: { name: 'socialMission', short: 'SAZ' }, // 'Sachzielerreichung'
  MOBILIZATION: { name: 'mobilization', short: 'MOB' },  // 'Mobilisierungsfähigkeit'
  GROWTH: { name: 'growth', short: 'WAC' }, // 'Wachstum'
  PROFITABILITY: { name: 'profitability', short: 'PRF' }, // 'Profitabilität'
  PERSONNEL_CHANGE: { name: 'personnelChange', short: 'MIT' }, // 'Mitarbeiterfluktuation'
}

export const CONSTRUCT_ARRAY = { // the order here pertains only to the text rendering below the table on the results pages
  M1: [
    CONSTRUCTS.ENTREPRENEURIAL_ORIENTATION,
    CONSTRUCTS.FUTURE_ORIENTATION,
    CONSTRUCTS.PROACTIVITY,
    CONSTRUCTS.INNOVATION,
    CONSTRUCTS.RISK_TAKING,
    CONSTRUCTS.ACTIVATION,
    CONSTRUCTS.AUTONOMY,
    CONSTRUCTS.COMMUNITY_MOBILIZATION,
    CONSTRUCTS.COOPERATIVE_MOBILIZATION,
    CONSTRUCTS.AGGRESSIVENESS,
  ],
  M2: [
    CONSTRUCTS.VISIONARY_LEADERSHIP,
    CONSTRUCTS.SERVANT_LEADERSHIP,
  ],
  M3: [
    CONSTRUCTS.CONSENSUS,
    CONSTRUCTS.STRATEGY,
    CONSTRUCTS.COMMUNICATION,
    CONSTRUCTS.HR_DIVERSITY,
    CONSTRUCTS.HR_LOYALTY,
  ],
  M4: [
    CONSTRUCTS.COMPLEXITY,
    CONSTRUCTS.FUNDING_OPPORTUNITY,
    CONSTRUCTS.HOSTILITY,
    CONSTRUCTS.LATITUDE,
  ],
  M5: [
    CONSTRUCTS.SOCIAL_MISSION,
    CONSTRUCTS.MOBILIZATION,
    CONSTRUCTS.GROWTH,
    CONSTRUCTS.PROFITABILITY,
    CONSTRUCTS.PERSONNEL_CHANGE,
  ],
}

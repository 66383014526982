import React from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { CONSTANTS } from '../../../utils/constants'
import { CONSTRUCTS } from '../../../utils/constructs'
import { roundToPlace } from '../../../utils/statisticCalc'

const grayLine = CONSTANTS.colors.gray
const blackLine = CONSTANTS.colors.blackLine

const getColumnStyling = (width, bottomColor, bottomWidth) => {
  return {
    width: `${width}%`,
    borderColor: grayLine,
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: bottomWidth ? bottomWidth : 1,
    borderBottomColor: bottomColor ? bottomColor : grayLine,
    display: 'flex',
    justifyContent: 'center', /* Center horizontally */
    alignItems: 'center', /* Center vertically */
    textAlign: 'center', /* Center text after linebreak or hyphen horizontally */
  }
}
const getCellStyling = (bold) => {
  return {
    margin: '4px 2px 4px',
    fontSize: 10,
    fontWeight: bold ? 600 : 400,
  }
}

const styles = StyleSheet.create({
  table: {
    margin: '20px 15px 0',
    display: 'flex',
    width: 'auto',
    borderColor: grayLine,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol10: getColumnStyling(10),
  tableCol10Black: getColumnStyling(10, blackLine),
  tableCol10BlackDouble: getColumnStyling(10, blackLine, 2),
  tableCol15: getColumnStyling(15),
  tableCol15Black: getColumnStyling(15, blackLine),
  tableCol15BlackDouble: getColumnStyling(15, blackLine, 2),
  tableCol20: getColumnStyling(20),
  tableCol20Black: getColumnStyling(20, blackLine),
  tableCol20BlackDouble: getColumnStyling(20, blackLine, 2),
  tableCol30: getColumnStyling(30),
  tableCol40: getColumnStyling(40),
  tableColEmpty: getColumnStyling(20, null, 0),
  tableCell: getCellStyling(),
  tableCellBold: getCellStyling(true),
  explanation: {
    fontSize: 10,
    marginLeft: 20,
  },
})

const modules = [ // module name for summary at end of table
  CONSTRUCTS.ENTREPRENEURIAL_ORIENTATION.name,
]
const sections = [ // main sub-modules or sections
  CONSTRUCTS.ACTIVATION.name,
  CONSTRUCTS.FUTURE_ORIENTATION.name,
]
const preSections = [  // construct right before the above section construct to darken border-bottom-color
  CONSTRUCTS.AGGRESSIVENESS.name,
  CONSTRUCTS.RISK_TAKING.name,
]
const preModules = [ // section right before the module summary to doubly darken border-bottom-color
  CONSTRUCTS.ACTIVATION.name,
]

const getBodyColStyles = (width, constructName) => {
  if (preModules.includes(constructName)) return `tableCol${width}BlackDouble`
  if (sections.includes(constructName) || preSections.includes(constructName)) return `tableCol${width}Black`
  return `tableCol${width}`
}
const getBodyCellStyles = (constructName) => {
  if (modules.includes(constructName) || sections.includes(constructName))
    return 'tableCellBold'
  return 'tableCell'
}

// modules where page break is necessary for aesthetic reasons:
// const newPageModules = ['module1', 'module3', 'module4', 'module5']

const PdfTable = ({ jtLabel, benchModAll, benchModCat, modAllResults, modCatResults, constructs, yearlyComp }) => {

  let yourPercentileText
  if (yearlyComp) {
    benchModAll = yearlyComp.benchmark
    benchModCat = null
    modAllResults = yearlyComp.lastSurveyResults
    modCatResults = yearlyComp.selectedSurveyResults
    yourPercentileText = yearlyComp.benchType === 'all' ?
      jtLabel.yourPercentileAllNPOs || '% Rank - All NPOs'
      : jtLabel.yourPercentileNPOsSameField || '% Rank - NPOs Same Field'
  }

  // console.log('constructs:', constructs)
  // console.log('yearlyComp:', yearlyComp)

  return (
    <View break>
      <View style={styles.table}>

        {/* Table Header, 1st Line */}
        <View style={styles.tableRow}>
          <View style={styles.tableColEmpty}>
            <Text style={styles.tableCell}></Text>
          </View>
          <View style={styles.tableCol20}>
            <Text style={styles.tableCellBold}>
              {yearlyComp?.benchType === 'cat' ?
                jtLabel.benchmarkNPOsOfSameField || 'Benchmark of NPOs in same field'
                : jtLabel.benchmarkAllNPOs || 'Benchmark of all NPOs'
              }
            </Text>
          </View>
          {yearlyComp ? (
            <>
              <View style={styles.tableCol30}>
                <Text style={styles.tableCellBold}>
                  {jtLabel?.yourNpoResults || 'Your NPO Results'}
                  {'\n'}
                  {yearlyComp.lastSurveyYear || ''}
                </Text>
              </View>
              <View style={styles.tableCol30}>
                <Text style={styles.tableCellBold}>
                  {jtLabel?.yourNpoResults || 'Your NPO Results'}
                  {'\n'}
                  {yearlyComp.selectedYear || `(${jtLabel?.comparisonYearNotSelected || 'Comparison year not selected'})`}
                </Text>
              </View>
            </>
          ) : (
            <>
              <View style={styles.tableCol20}>
                <Text style={styles.tableCellBold}>
                  {jtLabel.benchmarkNPOsOfSameField || 'Benchmark of NPOs in same field'}
                </Text>
              </View>
              <View style={styles.tableCol40}>
                <Text style={styles.tableCellBold}>
                  {jtLabel?.yourNpoResults || 'Your NPO Results'}
                </Text>
              </View>
            </>
          )}
        </View>

        {/* Table Header, 2nd Line */}
        <View style={styles.tableRow}>
          <View style={styles.tableCol20BlackDouble}>
            <Text style={styles.tableCellBold}>
              {jtLabel.construct || 'Construct'}
            </Text>
          </View>
          <View style={styles.tableCol10BlackDouble}>
            <Text style={styles.tableCellBold}>
              M
            </Text>
          </View>
          <View style={styles.tableCol10BlackDouble}>
            <Text style={styles.tableCellBold}>
              SD
            </Text>
          </View>
          <View style={yearlyComp ? styles.tableCol15BlackDouble : styles.tableCol10BlackDouble}>
            <Text style={styles.tableCellBold}>
              M
            </Text>
          </View>
          {yearlyComp ? (
            <>
              <View style={styles.tableCol15BlackDouble}>
                <Text style={styles.tableCellBold}>
                  {yourPercentileText}
                </Text>
              </View>
              <View style={styles.tableCol15BlackDouble}>
                <Text style={styles.tableCellBold}>
                  M
                </Text>
              </View>
              <View style={styles.tableCol15BlackDouble}>
                <Text style={styles.tableCellBold}>
                  {yourPercentileText}
                </Text>
              </View>
            </>
          ) : (
            <>
              <View style={styles.tableCol10BlackDouble}>
                <Text style={styles.tableCellBold}>
                  SD
                </Text>
              </View>
              <View style={styles.tableCol10BlackDouble}>
                <Text style={styles.tableCellBold}>
                  M
                </Text>
              </View>
              <View style={styles.tableCol15BlackDouble}>
                <Text style={styles.tableCellBold}>
                  {jtLabel.yourPercentileAllNPOs || '% Rank - All NPOs'}
                </Text>
              </View>
              <View style={styles.tableCol15BlackDouble}>
                <Text style={styles.tableCellBold}>
                  {jtLabel.yourPercentileNPOsSameField || '% Rank - NPOs Same Field'}
                </Text>
              </View>
            </>
          )
          }
        </View>

        {/* Table Body */}
        {Object.keys(modAllResults).map(key => {
          const construct = constructs.find(construct => construct.short === key)
          return (
            <View style={styles.tableRow} key={key}>
              {/* 1st column - construct name */}
              <View style={styles[getBodyColStyles(20, construct.name)]}>
                <Text style={styles[getBodyCellStyles(construct.name)]}>
                  {jtLabel[construct.name] || 'Waiting to get Construct...'}
                </Text>
              </View>
              {/* 2nd column - benchmark mean */}
              <View style={styles[getBodyColStyles(10, construct.name)]}>
                <Text style={styles[getBodyCellStyles(construct.name)]}>
                  {roundToPlace(benchModAll[construct.short].mean, 1)}
                </Text>
              </View>
              {/* 3rd column - benchmark standard deviation */}
              <View style={styles[getBodyColStyles(10, construct.name)]}>
                <Text style={styles[getBodyCellStyles(construct.name)]}>
                  {roundToPlace(benchModAll[construct.short].stdDev, 1)}
                </Text>
              </View>
              {/* 4th column - if yearly comparison: last survey mean
                               if normal results: category benchmark mean */}
              <View style={styles[getBodyColStyles(yearlyComp ? 15 : 10, construct.name)]}>
                <Text style={styles[getBodyCellStyles(construct.name)]}>
                  {yearlyComp ?
                    roundToPlace(modAllResults[construct.short]?.mean ?? 0, 1)
                    : roundToPlace(benchModCat?.[construct.short]?.mean ?? 0, 1) || '-'
                  }
                </Text>
              </View>
              {/* 5th column - if yearly comparison: last survey percentile
                               if normal results: category benchmark std. deviation */}
              <View style={styles[getBodyColStyles(yearlyComp ? 15 : 10, construct.name)]}>
                <Text style={styles[getBodyCellStyles(construct.name)]}>
                  {yearlyComp ?
                    modAllResults[construct.short].percentile
                    : roundToPlace(benchModCat?.[construct.short]?.stdDev ?? 0, 1) || '-'
                  }
                </Text>
              </View>
              {/* 6th column - if normal results, mean of these results */}
              {!yearlyComp &&
                <View style={styles[getBodyColStyles(10, construct.name)]}>
                  <Text style={styles[getBodyCellStyles(construct.name)]}>
                    {roundToPlace(modAllResults[construct.short].mean, 1)}
                  </Text>
                </View>
              }
              {/* 7th column of normal results - percentile with respect to overall benchmark
                  6th column of yearly comparison - comparison year mean */}
              <View style={styles[getBodyColStyles(15, construct.name)]}>
                <Text style={styles[getBodyCellStyles(construct.name)]}>
                  {yearlyComp ?
                    roundToPlace(modCatResults?.[construct.short].mean ?? 0, 1) || '-'
                    : modAllResults[construct.short].percentile
                  }
                </Text>
              </View>
              {/* 8th column of normal results - percentile with respect to category benchmark
                  7th column of yearly comparison - comparison year percentile */}
              <View style={styles[getBodyColStyles(15, construct.name)]}>
                <Text style={styles[getBodyCellStyles(construct.name)]}>
                  {modCatResults?.[construct.short]?.percentile ?? '-'}
                </Text>
              </View>
            </View>
          )
        })}

      </View>
      <Text style={styles.explanation}>
        M = {jtLabel.mean || 'Mean'}
        {'  '}
        SD = {jtLabel.standardDeviationLong || 'Standard Deviation'}
      </Text>
    </View>
  )
}


export default PdfTable

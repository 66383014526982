import deFlag from '../assets/flags/de.png'
import frFlag from '../assets/flags/fr.png'
import itFlag from '../assets/flags/it.png'
import ukFlag from '../assets/flags/gb.png'

export const LOGO_URL =
  'https://res.cloudinary.com/croative/image/upload/v1666945086/benchmark/logo-220x220.png'
export const MIN_PASS_LENGTH = 6
export const MIN_ORGANIZATION_NAME_LENGTH = 6
export const REDIRECTION_TIMEOUT = 3000
export const MAX_SURVEY_QUESTIONS_PER_PAGE = 7 // for pagination
export const MAX_DEMO_SURVEY_QUESTIONS_PER_PAGE = 6 // for pagination
// TODO: increase MIN_SAMPLE_SIZE to 5
export const MIN_SAMPLE_SIZE = 1 // For the benchmark of a specific organization category, Jonas email 20.6.24 Punkt O

export const MIN_MONTHS_BETWEEN_SURVEYS = 11 // Allow 1 month to fill out new surveys so that 1 survey/year is possible

export const CONSTANTS = Object.freeze({ // for toggle button colors see below
  colors: {
    white: '#ffffff',
    success: '#6FCF97', // success is same as green
    green: '#6FCF97',
    orange: '#f2994a',
    gray: '#aaaaaa',
    grayText: 'gray',  // '#808080'
    grayCardBackground: '#F2F2F2',
    lightGrayLines: '#cbc8c8',
    blackLine: '#000',
    benchLogoAnthracite: 'rgba(56,57,55)', // #383937 - we use rgba for react-charts
    benchLogoBlue: 'rgba(73,177,227)', // #49B1E3
    benchLogoOrange: 'rgba(241,121,2)', // #F17902
    benchLogoRed: 'rgba(190,16,32)', // #BE1020
    benchLogoYellow: 'rgba(254,213,0)', // #FED500
    EOGesamtmodellRed: 'rgba(255, 0, 0)', // #FF0000
    EOGesamtmodellRedSemiTransparent: 'rgba(255, 0, 0, 0.9)', // #FF0000
    EOGesamtmodellGreen: 'rgba(51, 153, 51)', // #339933
    EOGesamtmodellGreenSemiTransparent: 'rgba(51, 153, 51, 0.9)', // #339933
    EOGesamtmodellGray: 'rgba(128, 128, 128)', // #808080
    EOGesamtmodellGraySemiTransparent: 'rgba(128, 128, 128, 0.9)', // #808080
    EOGesamtmodellBlack: 'rgba(0, 0, 0)', // #000000
    EOGesamtmodellBlackSemiTransparent: 'rgba(0, 0, 0, 0.9)', // #000000
  },
})

export const VMI_URL = 'https://www.vmi.ch/'
export const KULTIVIERER_URL = 'https://kultivierer.ch/'
export const BVM_URL = 'https://www.bvmberatung.net/'
export const VMI_GOOGLE_MAP_URL = 'https://www.google.com/maps/place/VMI/@46.79397,7.158597,15z/data=!4m5!3m4!1s0x0:0x5d70368032ecf682!8m2!3d46.79397!4d7.158597'

// const tglBtnRed = '#EB5757' // red toggle button color; old colors
// const tglBtnDarkOrange = '#EF8400'
// const tglBtnLightOrange = '#EFBA00'
// const tglBtnYellow = '#DDE041'
// const tglBtnLightGreen = '#94C556'
// const tglBtnOldGreen = '#56C568'
const tglBtnViolet = '#4D03FC'  // new colors 5.6.24
const tglBtnBlue = '#0462F6'
const tglBtnCyan = '#03BCF2'
const tglBtnBlueGreen = '#03CFD8'
const tglBtnSpringGreen = '#03DEA7'
const tglBtnGreen = '#15E100'
const tglBtnColors = [tglBtnViolet, tglBtnBlue, tglBtnCyan, tglBtnBlueGreen, tglBtnSpringGreen, tglBtnGreen]
export const QUESTION_FORMAT_KEY = {
  A: { // demo questions: no offSwitch
    type: 'single', // single, dual, radio, checkbox, open
    answers: [1, 2, 3, 4, 5, 6],
    colors: tglBtnColors,
    defaultLabels: [
      'stimme überhaupt nicht zu',
      'stimme nicht zu',
      'stimme eher nicht zu',
      'stimme eher zu',
      'stimme zu',
      'stimme völlig zu',
    ],
    offSwitch: false,
  },
  B: { // demo questions: no offSwitch
    type: 'dual',
    answers: [6, 5, 4, 3, 2, 1],
    // answers: [1, 2, 3, 4, 5, 6],
    colors: tglBtnColors,
    defaultLabels: [
      'völlig die Option links',
      'die Option links',
      'eher die Option links',
      'eher die Option rechts',
      'die Option rechts',
      'völlig die Option rechts',
    ],
    offSwitch: false,
  },
  C: {
    type: 'single', // single, dual, radio, checkbox, open
    answers: [1, 2, 3, 4, 5, 6],
    colors: tglBtnColors,
    defaultLabels: [
      'stimme überhaupt nicht zu',
      'stimme nicht zu',
      'stimme eher nicht zu',
      'stimme eher zu',
      'stimme zu',
      'stimme völlig zu',
    ],
    offSwitch: true,
  },
  D: {
    type: 'dual',
    answers: [6, 5, 4, 3, 2, 1],
    // answers: [1, 2, 3, 4, 5, 6],
    colors: tglBtnColors,
    defaultLabels: [
      'völlig die Option links',
      'die Option links',
      'eher die Option links',
      'eher die Option rechts',
      'die Option rechts',
      'völlig die Option rechts',
    ],
    offSwitch: true,
  },
  E: {
    type: 'text',
    answers: null,
    colors: null,
    offSwitch: false,
  },
  F: {
    type: 'radio',
    answers: null,
    colors: CONSTANTS.colors.benchLogoOrange,
    offSwitch: false,
  },
  G: {
    type: 'checkbox',
    answers: null,
    colors: CONSTANTS.colors.benchLogoOrange,
    offSwitch: false,
  },
  H: {
    type: 'subQuestions',
    answers: [1, 2, 3, 4, 5, 6],
    colors: tglBtnColors,
    defaultLabels: [
      'stimme überhaupt nicht zu',
      'stimme nicht zu',
      'stimme eher nicht zu',
      'stimme eher zu',
      'stimme zu',
      'stimme völlig zu',
    ],
    offSwitch: false,
  },
}

// KVE2c has the answer values reversed, that is [6, 5, 4, 3, 2, 1], like format D above
export const KVE2c = 'In unserem Bereich sind wir mit steigendem Kostendruck und sinkenden Margen konfrontiert.'

const flagStyle = {
  width: '28px',
}
export const LANGUAGES = {
  de: {
    flag: <img src={deFlag} style={flagStyle} alt='german flag' />,
    name: 'Deutsch',
  },
  fr: {
    flag: <img src={frFlag} style={flagStyle} alt='French flag' />,
    name: 'Français',
  },
  it: {
    flag: <img src={itFlag} style={flagStyle} alt='Italian flag' />,
    name: 'Italiano',
  },
  en: {
    flag: <img src={ukFlag} style={flagStyle} alt='UK flag' />,
    name: 'English',
  },
}

export const DEFAULT_LANGUAGE = 'de'


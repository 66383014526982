import { getDataForChart } from '../../../utils/reactChartConfig'
import { CONSTRUCTS } from '../../../utils/constructs'

/**
 * called by ResultsModule5.js
 * @param jtLabels, object, 'label' portion of the jsonText object from Redux
 * @param benchMod5All, object, benchmark of all organization categories
 * @param modBenchCatResults, object, comparison of user's organization category benchmark with respect to overall benchmark
 * @param mod5AllResults, object, the latest results of the user's organization with respect to overall benchmark
 * @param mod5CatResults, object, the latest results of the user's organization with respect to category benchmark
 * @param yearlyComp, object, containing all the info and data for a yearly comparison (or null)
 * @param type, string, either 'summary' (overall result) or the section name (not yet used)
 * @return object, the chart data for Module 5
 *
 * Note: for yearly comparison, we use the following variables slightly differently:
 * benchMod5All = benchmark, either for all NPOs ('all') or only the NPOs of the same category ('cat')
 * modBenchCatResults = last survey results
 * mod5AllResults = results of selected year's survey (or null)
 * mod5CatResults = always null
 */
export const getChartDataMod5 = (jtLabels, benchMod5All, modBenchCatResults, mod5AllResults, mod5CatResults, yearlyComp, type) => {
  if (yearlyComp) {
    benchMod5All = yearlyComp.benchmark
    modBenchCatResults = yearlyComp.lastSurveyResults
    mod5AllResults = yearlyComp.selectedSurveyResults
    mod5CatResults = null
  }
  let chartLabels, benchAllData, benchCatResultsData, allResultsData, catResultsData
  switch (type) {
    case 'summary':
      chartLabels = [  // the order here pertains only to the chart
        `${jtLabels[CONSTRUCTS.SOCIAL_MISSION.name] || 'Social Mission'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.MOBILIZATION.name] || 'Mobilization'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.GROWTH.name] || 'Growth'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.PROFITABILITY.name] || 'Profitabilty'}`.toUpperCase(),
        `${jtLabels[CONSTRUCTS.PERSONNEL_CHANGE.name] || 'Personnel Change'}`.toUpperCase(),
      ]
      benchAllData = [
        benchMod5All.SAZ.stdDev === 0 ? 100 : 50,
        benchMod5All.MOB.stdDev === 0 ? 100 : 50,
        benchMod5All.WAC.stdDev === 0 ? 100 : 50,
        benchMod5All.PRF.stdDev === 0 ? 100 : 50,
        benchMod5All.MIT.stdDev === 0 ? 100 : 50,
      ]
      benchCatResultsData = [
        modBenchCatResults?.SAZ.percentile,
        modBenchCatResults?.MOB.percentile,
        modBenchCatResults?.WAC.percentile,
        modBenchCatResults?.PRF.percentile,
        modBenchCatResults?.MIT.percentile,
      ]
      allResultsData = [
        mod5AllResults?.SAZ.percentile,
        mod5AllResults?.MOB.percentile,
        mod5AllResults?.WAC.percentile,
        mod5AllResults?.PRF.percentile,
        mod5AllResults?.MIT.percentile,
      ]
      catResultsData = [
        mod5CatResults?.SAZ.percentile,
        mod5CatResults?.MOB.percentile,
        mod5CatResults?.WAC.percentile,
        mod5CatResults?.PRF.percentile,
        mod5CatResults?.MIT.percentile,
      ]
      break
    default:
      console.error('No match found for chart type:', type)
  }

  return getDataForChart(jtLabels, chartLabels, benchAllData, benchCatResultsData, allResultsData, catResultsData, yearlyComp)
}
